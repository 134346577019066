import React from 'react';
import styled from 'styled-components';
import StyledHeaderImage from '../components/header-image';
import Layout from '../components/layout';
import ContactSection from '../components/contact-section';

let formLinks = [
  {
    link:
      'https://1031-connect.s3.amazonaws.com/1031applicationforms/Application+Form.pdf',
    name: 'Application Form',
  },
  {
    link:
      'https://1031-connect.s3.amazonaws.com/1031applicationforms/Assignment+Acceptance+Notice+and+Direction+to+Convey.pdf',
    name: 'Assignment Acceptance Notice',
  },
  {
    link:
      'https://1031-connect.s3.amazonaws.com/1031applicationforms/Identification+of+Replacement+Property.pdf',
    name: 'Identification of Replacement Property',
  },
];

const FormLink = styled.a`
  text-decoration: none;
  font-size: 1.5em;
  background-color: #346993;
  color: white;
  padding: 1em 2em;
  margin: 1em 2em;
  border-radius: 24px;
  vertical-align: middle;

  @media (hover: hover) {
    &:hover {
      background-color: #4d7ea3;
    }
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
`;

const Icon = styled.img`
  margin-left: 2em;
  width: 48px;
  vertical-align: middle;
`;

const FormLine = styled.span`
  display: flex;
  align-items: center;
`;

const FormsPage = () => {
  const forms = React.useMemo(() => {
    return formLinks.map(({ name, link }) => (
      <FormLink key={name} href={link}>
        <FormLine>
          {name}
          <Icon src={require('../images/pdf.svg')} alt="pdf icon" />
        </FormLine>
      </FormLink>
    ));
  }, []);

  return (
    <Layout>
      <StyledHeaderImage page="forms" text="Forms" />
      <FormWrapper>{forms}</FormWrapper>
      <ContactSection
        header="Trouble with the forms?"
        subheader="We'd be glad to help!"
        buttonText="Contact Us"
      />
    </Layout>
  );
};

export default FormsPage;
